.wrapper {
  padding: 100px 0;
  background-color: #eee;

  &__title {
    text-align: center;
    margin: 0 0 12px;
    font-size: 35px;
    text-transform: uppercase;
    color: #232f3f;
    font-weight: 900;
    line-height: 1.5;

    @media (max-width: 986px) {
      font-size: 25px;
      text-align: center;
    }
  }

  &__subtitle {
    color: #000;
    font-style: italic;
    font-weight: 500;
    line-height: 1.5;
    font-size: 16px;
    text-align: center;

    @media (max-width: 986px) {
      font-size: 14px;
      text-align: center;
    }
  }

  &__sizer {
    margin-top: 32px !important;
    display: flex;
    justify-content: center;
    align-items: center;

    a button {
      color: #fff;
      background: #ff9900;
      font-size: 18px;
      font-weight: 700;
      padding: 12px 24px;
      border-radius: 50px;
    }
  }
}
