.wrapper {
  width: 100%;
  margin: 0 auto;
  display: block;
  max-width: 1400px;
  min-height: 100vh;
}

.container {
  width: 100%;
  margin: 0 auto;
  max-width: 1200px;

  @media (max-width: 1280px) {
    max-width: 100%;
    padding: 0 32px;
  }

  @media (max-width: 568px) {
    max-width: 100%;
    padding: 0 16px;
  }
}
