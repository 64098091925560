.wrapper {
  padding: 100px 40px;
  background-color: #fff;

  @media (max-width: 568px) {
    padding: 100px 16px;
  }

  div {
    padding: 0;
  }

  &__title {
    text-align: center;
    margin: 0 0 25px;
    font-size: 35px;
    text-transform: uppercase;
    color: #232f3f;
    font-weight: 900;
    line-height: 1.5;

    @media (max-width: 986px) {
      font-size: 25px;
      text-align: center;
    }
  }

  &__list {
    gap: 50px;
    display: flex;
    flex-direction: column;

    &__gaper {
      gap: 50px;
      display: flex;
      padding-left: 32px;
      flex-direction: column;
      margin-top: 60px;
      padding: 0 5% !important;

      @media (max-width: 986px) {
        gap: 60px;
      }
    }

    &__container {
      display: flex;

      @media (max-width: 986px) {
        flex-direction: column;
      }

      &_name {
        font-size: 23px;
        text-transform: uppercase;
        transform-origin: center;
        text-align: center;
        font-weight: bold;
        transition: all 0.25s;
        writing-mode: vertical-lr;
        transform: translate(-20px) rotate(-180deg);
        display: flex;
        justify-content: center;
        align-items: flex-end;

        @media (max-width: 986px) {
          transform: none;
          writing-mode: unset;
          margin-bottom: 40px;
        }
      }

      &__items {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        gap: 40px;

        @media (max-width: 986px) {
          justify-content: center;
        }

        @media (max-width: 568px) {
          flex-direction: column;
        }

        &__item {
          padding: 20px !important;
          width: 100%;
          height: 100px;
          max-width: 190px;
          display: flex;
          border: solid 1px #dddddd;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          position: relative;
          border-radius: 8px;
          overflow: hidden;

          @media (max-width: 568px) {
            max-width: 100%;
            height: 250px;
            padding: 30px 40px !important;
          }

          &_vacantion {
            position: absolute;
            top: -23px;
            right: -33px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 10;

            svg {
              width: 95px;

              path {
                fill: #ff9900;
              }
            }

            p {
              position: absolute;
              top: 5px;
              font-size: 14px;
              color: #fff;
            }

            &_button {
              background: #ff9900;
              width: 100%;
              padding: 8px 16px;
              margin-top: 12px;
              color: #fff;
              border-radius: 8px;
              text-align: center;

              @media (max-width: 568px) {
                padding: 16px;
              }
            }
          }
        }
      }
    }
  }
}

.soon {
  width: 100%;
  height: 40vh;
  background: rgba(35, 47, 63, 0.85);
  position: relative;

  @media (max-width: 986px) {
    height: 30vh;
  }

  &::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 25px;
    bottom: 0;
    height: 125px;
    width: 125px;
    background: url('~/public/soon.svg') center no-repeat;
    background-size: 100%;
    margin: auto;
    animation: swing 2s infinite alternate ease-in-out;
    transform-origin: top center;

    @keyframes swing {
      0% {
        transform: rotate(5deg);
      }
      100% {
        transform: rotate(-7deg);
      }
    }
  }
}

.tooltip {
  padding: 8px;
}
