.wrapper {
  padding: 100px 0 150px;
  background-image: url('../..//assets/images/Frame33503.png');

  @media (max-width: 986px) {
    background-image: unset;
    padding: 0;
  }
}

.title {
  text-align: center;
  margin: 0 0 25px;
  font-size: 35px;
  text-transform: uppercase;
  color: #232f3f;
  font-weight: 900;
  line-height: 1.5;
  margin-bottom: 60px;

  @media (max-width: 986px) {
    font-size: 25px;
    text-align: center;
  }
}

.timer {
  margin: 100px 0 120px;

  @media (max-width: 986px) {
    margin-bottom: 0;
  }
}

.one {
  position: relative;

  @media (max-width: 986px) {
    padding: 0;
  }

  &_content {
    display: flex;
    justify-content: space-between;
    position: relative;

    @media (max-width: 986px) {
      flex-direction: column;
    }

    &__left {
      padding: 50px;
      background-color: white;
      display: flex;
      width: 100%;
      max-width: 62.5%;
      text-align: center;
      flex-direction: column;

      @media (max-width: 986px) {
        max-width: 100%;
        padding: 100px 0;
      }

      &_content {
        max-width: calc(100% - 150px);

        @media (max-width: 986px) {
          max-width: 100%;
          text-align: center;
        }

        &_title {
          font-size: 35px;
          font-weight: bold;
          line-height: 1.5;
          text-align: left;
          color: #555;
          margin-bottom: 30px;

          @media (max-width: 986px) {
            font-size: 25px;
            text-align: center;
          }
        }

        &_description {
          font-size: 16px;
          line-height: 2;
          color: #555;
          margin-bottom: 30px;

          &_link {
            font-weight: bold;
            color: #23527c;
          }
        }
      }
    }

    &__right {
      display: flex;
      width: 100%;
      padding: 50px;
      position: absolute;
      right: 0;
      background-color: #232f3f;
      max-width: 50%;
      z-index: 1;
      top: 65px;
      text-align: left;
      flex-direction: column;
      border-top: #ff9900 10px solid;
      color: white;

      @media (max-width: 986px) {
        position: static;
        height: auto !important;
        min-height: unset;
        margin-left: -40px;
        min-width: 100vw;
        padding: 40px;
      }

      @media (max-width: 568px) {
        margin-left: -16px;
        width: calc(100% + 32px);
      }

      &_title {
        font-size: 35px;
        font-weight: bold;
        color: white;
        line-height: 1.5;
        margin-bottom: 30px;

        @media (max-width: 986px) {
          font-size: 25px;
          text-align: center;
        }
      }

      &_list {
        padding-left: 8px;

        &_item {
          text-align: left;
          line-height: 1.5;
          margin-bottom: 7px;
          list-style-type: circle;
        }
      }

      &__footer {
        margin-top: 30px;
        font-size: 16px;
        font-weight: bold;
        line-height: 1.5;

        a {
          color: #0095fa;

          &:hover {
            text-decoration: underline;
          }
        }

        span {
          color: #337ab7;
        }
      }
    }
  }
}
