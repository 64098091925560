.wrapper {
  background: #f9f9f9;
  border-radius: 16px;
  padding: 24px 32px;
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  min-height: 300px;
  height: 100%;

  &__feedback {
    font-size: 20px;
    line-height: 28px;
    font-weight: 700;
    color: #253237;
  }

  img {
    max-width: 600px;
    height: 500px;
    object-fit: contain;

    @media (max-width: 768px) {
      max-width: calc(100vw - 200px);
    }

    @media (max-width: 568px) {
      max-width: calc(100vw - 60px);
    }
  }

  a {
    margin-top: 20px;
    color: #0095fa;
    font-weight: bold;

    &:hover {
      text-decoration: underline;
    }
  }
}
