.wrapper {
  padding: 60px 0 30px;
  background-color: #232f3f;

  &__title {
    text-align: center;
    margin: 0 0 25px;
    font-size: 35px;
    text-transform: uppercase;
    color: #fff;
    font-weight: 900;
    line-height: 1.5;

    @media (max-width: 986px) {
      font-size: 25px;
    }
  }
}

.share {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;

  &_item {
    width: 60px;
    height: 60px;

    &:first-child {
      width: 50px;
      height: 50px;

      svg {
        width: 50px;
        height: 50px;
      }
    }

    svg {
      width: 60px;
      height: 60px;

      path {
        fill: #fff;
      }
    }

    &_meet {
      path:last-child {
        fill: #232f3f;
      }
    }

    &_linkedin {
      width: 50px;
      height: 50px;

      svg {
        width: 50px;
        height: 50px;
      }

      rect {
        fill: #fff;
      }

      path {
        fill: #232f3f !important;
      }
    }
  }
}

.logo {
  margin: 0 auto 20px;
  max-width: 300px;
  display: block;
}

.text {
  font-size: 18px;
  text-align: center;
  color: #fff;
}

.title {
  text-align: center;
  color: #fff;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
  border-top: 1px solid #fff;
  padding-top: 26px;

  img {
    height: 57px;
  }

  @media (max-width: 986px) {
    flex-direction: column;
    gap: 40px;
  }
}
