.wrapper {
  width: 100%;
  padding: 20px;
  max-width: 222px;
  display: flex;
  border-radius: 16px;
  flex-direction: column;
  background: rgb(255 147 0);

  @media (max-width: 1024px) {
    padding: 10px 12px;
  }

  @media (max-width: 986px) {
    background: #ff9900;
  }

  &__date {
    text-align: center;
    -webkit-text-stroke: 3px #fff;
    color: #0000;
    text-align: center;
    font-size: 96px;
    font-weight: 700;
    line-height: 120px;

    @media (max-width: 1024px) {
      font-size: 32px;
      font-weight: 700;
      line-height: 48px;
    }

    @media (max-width: 986px) {
      -webkit-text-stroke: 1px #fff;
    }
  }

  p {
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    color: #fff;
    text-align: center;
    text-transform: uppercase;

    @media (max-width: 1024px) {
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
    }
  }
}
