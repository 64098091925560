.wrapper {
  padding: 80px 0;
  min-height: 60vh;
  background-color: #232f3f;

  &__title {
    text-align: center;
    margin: 0 0 25px;
    font-size: 35px;
    text-transform: uppercase;
    color: #fff;
    font-weight: 900;
    line-height: 1.5;
    margin-bottom: 20px;
  }

  &__subtitle {
    text-align: center;
    font-size: 18px;
    color: #fff;
    margin-top: 30px;
  }

  &__sizer {
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 768px) {
      padding: 0 40px;
    }

    a button {
      color: #fff;
      background: #ff9900;
      font-size: 22px;
      font-weight: 700;
      padding: 24px 48px;
      border-radius: 50px;
      min-width: 300px;
    }

    iframe {
      max-width: 600px;
      height: 400px;
      width: 100%;
    }
  }

  &__users {
    margin-top: 80px !important;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 55px;

    &__user {
      max-width: 220px;
      width: 100%;
      text-align: center;
      display: flex;
      align-items: center;
      gap: 8px;
      flex-direction: column;
      justify-content: center;
      position: relative;
      border: dashed 2px #fff;

      &__mt {
        margin-top: -50px;
      }

      &__img {
        width: 80px;
        left: -40px;
        top: 30px;
        height: 80px;
        object-fit: cover;
        position: absolute;
        border-radius: 50%;
      }

      &_name {
        color: white;
        font-weight: 700;
      }
      &_position {
        color: white;
        font-size: 12px;
      }
    }
  }
}

.container {
  margin-top: 80px;

  @media (max-width: 1024px) {
    max-width: 100vw;
    overflow: hidden;
  }

  &__title {
    text-align: center;
    margin: 0 0 25px;
    font-size: 35px;
    text-transform: uppercase;
    color: #000;
    font-weight: 900;
    line-height: 1.5;
    margin-bottom: 20px;
    margin-top: 40px;
  }
}
