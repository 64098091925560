.wrapper {
  padding: 80px 0;
  min-height: 60vh;
  background-color: #232f3f;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  a {
    background: #ff9900;
    border-radius: 16px;
    max-width: 350px;
    width: 100%;
    padding: 18px 0;
    margin-top: 40px;
    color: #fff;
    font-weight: 700;
    font-size: 18px;
    text-align: center;
  }
}

.title {
  text-align: center;
  margin: 0 0 25px;
  font-size: 35px;
  text-transform: uppercase;
  color: #fff;
  font-weight: 900;
  line-height: 1.5;
  margin-bottom: 26px;

  @media (max-width: 986px) {
    font-size: 25px;
    text-align: center;
  }
}

.subtitle {
  text-align: center;
  margin: 0 0 25px;
  font-size: 18px;
  color: #fff;
  margin-bottom: 26px;
}
