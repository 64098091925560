@import './vars.scss';

p,
a,
q,
s,
b,
u,
i,
dl,
dt,
dd,
ol,
ul,
li,
tr,
th,
td,
em,
h1,
h2,
h3,
h4,
h5,
h6,
tt,
sub,
sup,
var,
nav,
pre,
del,
dfn,
img,
ins,
kbd,
div,
big,
span,
form,
menu,
ruby,
html,
body,
cite,
abbr,
code,
samp,
time,
mark,
audio,
video,
label,
tbody,
tfoot,
thead,
aside,
embed,
small,
table,
canvas,
legend,
strike,
strong,
figure,
center,
applet,
object,
iframe,
footer,
header,
hgroup,
output,
caption,
details,
article,
section,
summary,
acronym,
address,
fieldset,
figcaption,
blockquote {
  border: 0;
  padding: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline;
}

nav,
menu,
aside,
figure,
footer,
header,
hgroup,
details,
article,
section,
figcaption {
  display: block;
}

ol,
ul {
  list-style: none;
}

table {
  border-spacing: 0;
  border-collapse: collapse;
}

img,
svg {
  user-select: none;
}

img {
  max-width: 100%;
}

* {
  margin: 0;
  padding: 0;
  outline: none;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  font-variant-ligatures: none !important;
  -webkit-tap-highlight-color: transparent;
  font-family: 'Roboto', sans-serif !important;
}

input {
  font-size: 100%;
  box-shadow: none;
  -o-box-shadow: none;
  -ms-box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;

  &[type='text'],
  &[type='number'],
  &[type='tel'],
  &[type='phone'],
  &[type='password'] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 1000px green inset;
    transition: background-color 1000s;
    -webkit-text-fill-color: white;
    caret-color: white;
  }

  &[type='number']::-webkit-outer-spin-button,
  &[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  &[type='number'] {
    -moz-appearance: textfield;
    appearance: textfield;
  }
}

a,
button {
  border: 0;
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
}

svg {
  cursor: pointer;
}

html {
  height: 100%;
  width: 100%;
}

#root {
  width: 100%;
}

body {
  padding: 0;
  width: 100%;
  height: 100%;
  color: black;
  font-size: 16px;
  font-weight: 400;
  background-attachment: fixed;
}

#Layer_2 .cls-1,
.cls-2 {
  fill: #fff !important;
}

.statisticSlider {
  .swiper {
    padding: 0 40px 60px;
    max-width: 700px;

    @media (max-width: 568px) {
      padding: 0 0 40px;
    }
  }
}

.speakerSlider {
  .swiper {
    margin-left: -40px;
    margin-right: -40px;
    padding: 0 40px 80px;

    @media (max-width: 1310px) {
      margin-left: -20px;
      margin-right: -20px;
    }

    @media (max-width: 1024px) {
      margin-left: -20px;
      margin-right: -20px;
    }
  }
}

.speakerSlider,
.statisticSlider {
  .swiper-button-prev {
    margin-left: -10px;
    color: #ff9900;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: red;

    &::after {
      width: 20px;
      height: 40px;
    }
  }

  .swiper-button-next {
    color: #ff9900;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: red;

    &::after {
      width: 20px;
      height: 40px;
    }
  }

  .swiper-pagination {
    gap: 8px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 568px) {
      gap: 2px;
    }

    span {
      width: 10px;
      height: 10px;
      background: #ff9900;
      border-radius: 50%;
    }
  }

  .swaper--prev {
    position: absolute;
    width: 50px;
    height: 50px;
    background: #ff9900;
    border-radius: 50%;
    z-index: 20;
    display: flex;
    justify-content: center;
    align-items: center;
    top: calc(50% - 50px);
    left: 0;

    @media (max-width: 1024px) {
      display: none;
    }

    svg {
      width: 32px;
      height: 32px;
      transform: rotate(90deg);
      margin-left: -1px;
    }

    &:disabled {
      background: #ccc;
    }
  }

  .swaper--next {
    position: absolute;
    width: 50px;
    height: 50px;
    background: #ff9900;
    border-radius: 50%;
    z-index: 20;
    display: flex;
    justify-content: center;
    align-items: center;
    top: calc(50% - 50px);
    right: 0;

    @media (max-width: 1024px) {
      display: none;
    }

    svg {
      width: 32px;
      height: 32px;
      transform: rotate(-90deg);
    }

    &:disabled {
      background: #ccc;
    }
  }
}

.map-widget-layout-view__maps-links {
  .button__text {
    display: none;
  }
}
