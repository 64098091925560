.three {
  background-color: #fff;
  padding: 50px 0;

  &__content {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 986px) {
      flex-direction: column-reverse;
    }

    &_left {
      position: relative;
      width: 100%;

      @media (max-width: 986px) {
        max-width: 100%;
      }

      &__map {
        width: 100%;
        max-width: 100%;
        background: white;
        height: 516px;
      }

      &__badge {
        left: 100%;
        width: 70px;
        height: 80%;
        margin: auto;
        bottom: 0;
        top: 0;
        background: #ff9900;
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(0, 0, 0, 0.4);
        position: absolute;

        @media (max-width: 986px) {
          display: none;
        }
      }

      &__name {
        font-size: 75px;
        position: absolute;
        left: 100%;
        top: 0;
        bottom: 0;
        text-align: center;
        transform: rotate(-180deg);
        margin: 0 0 0 30px;
        writing-mode: vertical-lr;
        text-transform: uppercase;
        font-weight: 900;
        letter-spacing: 10px;
        text-shadow: -2px 2px 3px rgba(0, 0, 0, 0.25);
        color: #232f3f;

        @media (max-width: 986px) {
          display: none;
        }
      }
    }

    &_right {
      width: 100%;
      height: 100%;
      max-width: 35%;

      @media (max-width: 986px) {
        max-width: 100%;
        display: flex;
      }

      @media (max-width: 568px) {
        flex-direction: column;
      }

      &__item {
        gap: 30px;
        color: #555;
        padding: 80px 30px;
        display: flex;
        align-items: center;
        flex-direction: column;

        @media (max-width: 986px) {
          flex-direction: column;
          width: 100%;
          padding: 30px;
        }

        &_active {
          background-color: #232f3f;
          color: white;
        }

        &_left {
          gap: 10px;
          display: flex;
          line-height: 1.5;
          text-align: center;
          flex-direction: column;
          color: #ff9900;

          &_date {
            font-size: 16px;
          }

          &_name {
            font-size: 30px;
          }
        }

        &_right {
          gap: 7px;
          display: flex;
          flex-direction: column;
          text-align: center;

          &_title {
            font-weight: 700;
          }
        }
      }
    }
  }
}
