.wrapper {
  padding: 100px 0;
  color: white;

  &__title {
    text-transform: uppercase;
    color: #000;
    font-weight: 900;
    line-height: 1.5;
    font-size: 35px;
    margin-bottom: 25px;
    text-align: center;

    @media (max-width: 986px) {
      font-size: 25px;
      text-align: center;
    }
  }

  &__subtitle {
    color: #000;
    font-weight: 500;
    line-height: 1.5;
    font-size: 16px;
    margin-bottom: 45px;
    text-align: center;

    @media (max-width: 986px) {
      font-size: 14px;
      text-align: center;
    }
  }
}

.content {
  color: #000;
  border: 1px solid #e0e0e0;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.navigation {
  background: #eef1f3;
  padding: 8px;
  display: flex;
  align-items: center;
  position: relative;

  &__item {
    padding: 16px;
    flex: 1;
    text-align: center;
    cursor: pointer;
    font-size: 18px;
    line-height: 28px;
    font-weight: bold;
    border-radius: 8px;
    z-index: 1;
    transition: color 0.4s linear;

    &_active {
      color: #fff;
    }

    &_spiner {
      left: 8px;
      top: 8px;
      width: calc((100% - 16px) / 3);
      height: calc(100% - 16px);
      position: absolute;
      background: #ff9900;
      border-radius: 8px;
      transition: all 0.4s linear;
    }
  }
}

.card {
  background: #fff;
  border-bottom: 1px solid #e0e0e0;
  transition: all 0.3s ease-in;

  &__active {
    background: #e0e3e6;
  }

  &__track {
    background: #f7fafc;

    &_active {
      background: #c1ced58a;
    }
  }

  &__content {
    display: grid;
    grid-template-columns: 1fr 2fr 2fr 0.5fr;

    @media (max-width: 986px) {
      grid-template-columns: unset;
      grid-template:
        'date date date arrow'
        'title title title arrow'
        'speacker speacker speacker speacker';
    }

    @media (max-width: 568px) {
      grid-template-columns: unset;
      grid-template:
        'date date date arrow'
        'title title title title'
        'speacker speacker speacker speacker';
    }
  }

  &__date {
    padding: 32px;

    @media (max-width: 986px) {
      grid-area: date;
      padding: 16px 32px;
    }

    @media (max-width: 986px) {
      padding-top: 32px;
    }

    p {
      font-size: 18px;
      line-height: 32px;
      max-width: 80px;

      @media (max-width: 986px) {
        max-width: 100%;
      }
    }
  }

  &__title {
    font-size: 18px;
    line-height: 24px;
    font-weight: bold;
    margin-bottom: 8px;

    span {
      color: #fff;
      background: #ff9900;
      padding: 4px 8px;
      border-radius: 8px;
      font-size: 14px;
      text-transform: uppercase;
    }
  }

  &__speacker {
    padding: 32px;
    display: flex;
    flex-direction: column;
    gap: 16px;

    @media (max-width: 986px) {
      grid-area: speacker;
      padding: 16px 32px;
      flex-direction: row;
    }

    @media (max-width: 568px) {
      display: none;
    }

    &__item {
      gap: 8px;
      display: flex;
      align-items: center;

      @media (max-width: 968px) {
        max-width: 50%;
      }

      @media (max-width: 568px) {
        max-width: 100%;
      }

      &_top {
        img {
          object-position: top;
        }
      }

      img {
        max-width: 50px;
        min-width: 50px;
        height: 50px;
        border-radius: 50%;
        border: 2px solid #ff9900;
        object-fit: cover;
      }

      p {
        font-size: 16px;
        font-weight: bold;
        margin-top: 0 !important;
        margin-bottom: 2px !important;
        color: #ff9900 !important;
      }

      span {
        font-size: 14px;
        color: #263238;
      }

      div {
        width: 100%;
      }
    }

    &__mobile {
      @media (max-width: 568px) {
        margin: 16px 0;
        gap: 16px;
        display: flex;
        flex-direction: column;
      }
    }
  }

  &__item {
    padding: 32px;

    @media (max-width: 986px) {
      padding: 16px 32px;

      &:nth-child(2) {
        grid-area: title;
      }

      &:last-child {
        grid-area: arrow;
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }
    }

    @media (max-width: 568px) {
      &:nth-child(2) {
        padding-bottom: 32px;
      }

      &:last-child {
        padding-top: 32px;
        align-items: start;
      }
    }

    svg {
      path {
        fill: #000;
      }
    }

    &_arrow {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.2s ease-in-out;

      &:hover {
        background: #eef1f3;
      }

      @media (max-width: 986px) {
        background: #eef1f3;
      }
    }
  }

  &__info {
    display: grid;
    grid-template-columns: 1fr 4fr;

    @media (max-width: 986px) {
      grid-template-columns: 0 1fr;
    }

    &_title {
      margin: 8px 0;
    }

    &_content {
      margin: 16px;
      border-top: 2px solid #958e8e;

      @media (max-width: 568px) {
        margin-top: 0 !important;
      }

      @media (max-width: 986px) {
        margin: 16px 32px;
      }

      p {
        color: #616161;
        font-size: 16px;
        line-height: 24px;
      }

      p:first-child {
        font-weight: bold;
        font-size: 18px;
        line-height: 24px;
        margin: 8px 0 16px;
      }
    }
  }
}
