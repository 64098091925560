.wrapper {
  min-height: 260px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 20px;

  svg {
    width: 40px;
    height: 40px;

    path {
      fill: #ff9900;
    }
  }

  &__first {
    svg path {
      stroke: #ff9900;
    }
  }
}

.title {
  color: #ff9900;
  font-size: 36px;
  line-height: 48px;
  font-weight: 700;
  gap: 14px;
  display: flex;
  align-items: center;
  border: none !important;
}

.subtitle {
  font-size: 18px;
  line-height: 24px;
  color: #000;
  font-weight: 700;
  margin: 8px 0;
}

.text {
  font-size: 16px;
  line-height: 18px;
  color: #666;
  max-width: 400px;
  text-align: center;
}
